.topic_container {
    display: flex;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    max-height: 80%;
    min-height: 70%;
    justify-content: center;
    flex-wrap: wrap;
    align-content: stretch;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
}

.topic {
    text-align: center;
    font-family: Arial;
    text-transform: uppercase;
    font-weight:700;
    cursor: pointer;
    margin: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0;
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    word-break: break-all;
}   

.topic:hover {
    transform: scale(1.01);
}