.Comments .commentSendButton {
    white-space:nowrap;
    text-align: center;
    padding: 2px 15px;
    border: 1px solid rgb(166, 166, 166);
    border-radius: 5px;
    background-color:rgba(0,0,0,0);
    color:white;
    width:100px;
    font-size:12px;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.Comments .commentSendButton:hover {
    background-color:rgba(255,255,255,0.2);
}

.Comments {
    text-align: center;
}

.Comments .titleComments{
    color: white;
    font-weight: 500;
}

.Comments .commentText {
    color: white;
}

.Comments .commentsList {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: left;
}

.Comments textarea {
    width: 60%;
    height: 70px;
    display: inline;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
    color: white;
    background-color:rgba(255,255,255,0.0);
}