.ner_cloud {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 70%;
    justify-content: center;
    flex-wrap: wrap;
    align-content: stretch;
    text-align: center;
    align-items: center;
    overflow-y: scroll;
}
/* Tooltip container */
.tooltip {
position: relative;
display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
visibility: hidden;
width: 120px;
background-color: #555;
color: #fff;
text-align: center;
padding: 5px 0;
border-radius: 6px;
font-size: 14px;

/* Position the tooltip text */
position: absolute;
z-index: 1;
bottom: 125%;
left: 50%;
margin-left: -60px;

/* Fade in tooltip */
opacity: 0;
transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
visibility: visible;
opacity: 1;
}

.ner_word {
    text-align: center;
    font-family: Arial;
    text-transform: uppercase;
    font-weight:700;
    margin: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    word-break: break-all;
}