.card.graph {
    height: 450px;
    width: 47.9%;
    text-align: center;
}

.card.full_screen {
    width: 98% !important;
}

input, textarea, keygen, select, button {
    margin-top: 0em;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    font-variant-caps: normal;
    color: default;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
}

@media screen and (max-width: 900px) {
    .card.graph {
        width: 98%;
    }
    .card.full_screen {
        height: 1400px !important;
        width: 98% !important;
    }
  }

.graph-canvas {
    width: 100%;
    height: 350px;
}

.Modules p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.Modules {
    position: absolute;
    padding-top: 70px;
    color: rgb(180, 180, 180);
    text-align: left;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
}

@media screen and (max-width: 1165px) {
    .Modules {
        padding-top: 140px;
    }
}

@media screen and (max-width: 500px) {
    .Modules {
        padding-top: 210px;
    }
}


.small_text {
    font-size: 0.9em;
    color: rgb(180, 180, 180);
    cursor: pointer;
}

.Modules > p {
    text-align: left;
    margin-left: 20px;
    font-weight: 500;
    color: rgb(180, 180, 180);
}

#kpi_box {
    width: 100%;
    overflow-y: scroll;
    display: flex;
}

.kpi {
    flex: 1 1 0px;
    min-width: 140px;
    width: auto;
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
    /*border-bottom: 4px solid rgb(180,180,180);*/
}

.kpi > .kpi_title {
    text-align: center;
    height: 32px;
    color: white;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
}

.kpi > .kpi_value {
    text-align: center;
    font-size: 30px;
    color: white;
    line-height: 25px;
    font-weight: 700;
}

.kpi > .kpi_value > span > .subtext{
    font-size: 25px;
    font-weight: 700;
}

.graph > p:first-of-type {
    color: white;
    font-weight: 600;
}

#detail_container {
    width: 100%;
    height: 100%;
}

.filters {
    padding-right: 20px;
    padding-left: 20px;
    line-height: 35px;
    font-size: 13px;
}

.filters > span {
    margin-left: 30px;
}

@media screen and (max-width: 600px) {
    .filters > span {
        white-space:pre-wrap;
        margin-left: 30px;
    }
}


.apply {
    white-space:nowrap;
    text-align: center;
    padding: 2px 15px;
    border: 1px solid rgb(166, 166, 166);
    border-radius: 5px;
    background-color:rgba(0,0,0,0);
    color:white;
    width:100px;
    font-size:12px;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

#export_button.disabled {
    color: grey;
    cursor: wait !important;
}

.apply:hover {
    background-color:rgba(255,255,255,0.2);
}

#feedbacks-container {
    height: 700px;
}

.feedback {
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: 116px;
    overflow: hidden;
    vertical-align: center;
    word-break: break-all;
}

.feedback > .feedback_text {
    text-align: justify;
    padding-left: 10px;
    color: white;
    margin: 0;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.feedback > img {
    /*margin: 10px;
    margin-top: 15px;*/
    margin-left: 5px;
    float: left;
    height: 50%;
}

.feedback_step {
    margin:0 !important;
}

.feedback > .feedback_step {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.feedback_infos {
    text-decoration: underline;
    color: white;
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    margin:0;
    margin-left:10px;
}

.feedback_step > .step {
    color: white;
    line-height: 22px;
    height: 22px;
    font-size: 15px;
    padding: 6px;
    padding-left: 10px;
    padding-right: 20px;
    border-radius: 20px;
    margin:5px;
    display: inline-block;
}

.feedback_step > .step > img {
    filter: brightness(0) invert(1);
    height: 22px;
    margin-bottom: 6px;
    vertical-align: middle;
    margin-right: 10px;
}

.feedback:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.feedback:hover > p:after {
    content: ">";
    color: white;
    font-size: 20px;
    position: absolute;
    right: 3px;
    margin: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.last_50_flex {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.last_50 {
    position: relative;
    flex-grow : 1;
    overflow-y: scroll;
}

#hilight_grd {
    background: rgb(0,255,0);
    background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,255,0,1) 100%);
    opacity: 0.5;
    height: 20px;
    width: 100%;
}

#hilight_legend {
    width: 150px;
    margin-left: auto;
    margin-top: 30px;
    margin-right: auto;
}

#hilight_legend_txt {
    height: 15px;
    position: relative;
}

#hilight_legend_txt > p {
    position: absolute;
    top:0;
    margin:1px;
}

#hilight_legend_txt > p:first-child {
    left: 0;
    transform: translate(-50%);
}

#hilight_legend_txt > p:last-child {
    right: 0;
    transform: translate(50%);
}

.option_button {
    font-weight: 400;
    cursor: pointer;
    margin-left: 20px;
    text-decoration: underline;
    color: rgb(180, 180, 180);
}

.toCopy {
    background-color: white;
}

.toCopy .option_button {
    display: none;
}

.toCopy #hilight_legend {
    display: none;
}

.toCopy > .feedback_infos_div {
    background-color: rgba(0,0,0,0);
    color: black;
}

.toCopy p {
    background-color: rgba(0,0,0,0);
    color: black;
}

.toCopy .hilighted_feedback p {
    background-color: rgba(0,0,0,0);
    color: black;
}

.highlight_text {
    color: rgba(0, 0, 0, 0);
}

.hilighted_feedback {
    margin: 20px;
    position: relative;
    height: auto;
}


.hilighted_feedback > p {
    text-align: justify;
    line-height: 30px;
}

.hilighted_feedback > .main_text {
    white-space: pre-line;
    color: white;
    width: 100%;
    margin: 0;
}

.hilighted_feedback > .highlight_text {
    width: 100%;
    transform: translate(0, -100%);
    position: absolute;
    margin: 0;
}

.hlgt_4, .hlgt_3, .hlgt_2, .hlgt_m4, .hlgt_m3, .hlgt_m2, .hlgt_1, .hlgt_m1, .hlgt_n {
    position: relative;
    bottom: 1px;
    vertical-align: middle;
    display: inline;
    color: white;
    padding: 4px;
    margin-right: -4px;
    margin-left: -4px;
    line-height: 22px;
    border-radius: 5px;
}

.hlgt_4, .hlgt_3, .hlgt_2 {
    background-color: #07a617;
}
/* child at any level of the screenshot class */
.screenshot .hlgt_4, .screenshot .hlgt_3, .screenshot .hlgt_2 {
    text-decoration: underline;
    background-color: transparent;
    text-decoration-color: #07a617;
    color: #07a617;
    text-decoration-thickness: 2px;
}

.hlgt_m4, .hlgt_m3, .hlgt_m2 {
    background-color: #a60707;
}
/* child at any level of the screenshot class */
.screenshot .hlgt_m4, .screenshot .hlgt_m3, .screenshot .hlgt_m2 {
    text-decoration: underline;
    background-color: transparent;
    text-decoration-color: #a60707;
    color: #a60707;
    text-decoration-thickness: 5px;
}

.hlgt_1, .hlgt_m1 {
    background-color: #bdc706;
}
/* child at any level of the screenshot class */
.screenshot .hlgt_1, .screenshot .hlgt_m1 {
    text-decoration: underline;
    background-color: transparent;
    text-decoration-color: #bdc706;
    color: #bdc706;
    text-decoration-thickness: 5px;
}

.hlgt_n {
    background-color: rgba(80,80,80,1);
}
/* child at any level of the screenshot class */
.screenshot .hlgt_n {
    text-decoration: underline;
    background-color: transparent;
    text-decoration-color: rgba(80,80,80,1);
    color: rgb(172, 172, 172);
    text-decoration-thickness: 5px;
}

.last_50 > .loading_gif {
    width: 40px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, - 50%);
}

.topic_select {
    width: 200px;
    text-align: right;
}

.select_hilight {
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.status {
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.5);
    border: solid 2px rgba(255, 255, 255, 0.3);
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-size: 0;
}

.pastille {
    opacity: 0.7;
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 100px;
}

.feedback_infos_div {
    display: flex;
    justify-content: space-between;
}