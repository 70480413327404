.tag{
	background-color:rgb(60, 62, 95);
	border:1px solid #e9e9e9;
	padding:2px 0 2px 2px;
	border-radius:2px;
	display:inline;
}
.tag:focus-within{
	background-color:#e9e9e9;
	border-color:#a0a0a0
}
.tag-remove{
	color:#a0a0a0;
	font-size:75%;
	line-height:100%;
	cursor:pointer;
	background-color:transparent;
	border:none;
	outline:none
}
.tag-remove.disabled,.tag-remove.readOnly{
	cursor:not-allowed
}
.tag-remove:focus{
	color:#3c3c3c
}
.node>label{
	cursor:pointer;
	margin-left:2px
}
.tag-list{
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
	padding:0;
	margin:0
}
.tag-item{
	display:inline-block;
	margin:4px
}
.tag-item .search{
    background-color: rgba(0, 0, 0, 0);
	border:none;
	border-bottom:1px solid #ccc;
	outline:none
}
.tag-item:last-child{
	margin-right:4px
}
.node{
	list-style:none;
	white-space:nowrap;
	padding:4px
}
.node.leaf.collapsed{
	display:none
}
.node.disabled>*{
	color:gray;
	cursor:not-allowed
}
.node.match-in-children.hide .node-label{
	opacity:.5
}
.node.focused{
	background-color: rgb(60, 62, 95);
}
.toggle{
	white-space:pre;
	margin-right:4px;
	cursor:pointer
}
.toggle:after{
	content:" "
}
.toggle.collapsed:after{
	content:"+"
}
.toggle.expanded:after{
	content:"-"
}
.searchModeOn .toggle{
	display:none
}
.checkbox-item,.radio-item{
	vertical-align:middle;
	margin:0 4px 0 0
}
.checkbox-item.simple-select,.radio-item.simple-select{
	display:none
}
.hide:not(.match-in-children){
	display:none
}
.react-dropdown-tree-select .dropdown{
	position:relative;
    display:table;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger{
    padding:4px;
    min-width: 100px;
    line-height:30px;
    max-height: 30px;
    overflow: hidden;
	display:inline-block;
    border:1px solid #b9b9b9;
    border-radius: 10px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow{
	cursor:pointer
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after{
	content:"\25BC";
	vertical-align:middle;
	color:#3c3c3c;
	margin-right:2px
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after{
	content:"\25B2";
	vertical-align:middle;
	color:#3c3c3c;
	margin-right:2px
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled{
	cursor:not-allowed
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after{
	color:#b9b9b9
}
.react-dropdown-tree-select .dropdown .dropdown-content{
	max-height: 60vh;
	overflow: scroll;
	position:absolute;
	padding:4px;
	z-index:1;
	background:rgb(42, 44, 71);
	border-top:1px solid rgba(0,0,0,.05);
	-webkit-box-shadow:0 5px 8px rgba(0,0,0,.15);
	box-shadow:0 5px 8px rgba(0,0,0,.15)
}
.react-dropdown-tree-select .dropdown .dropdown-content .search{
	width:100%;
	border:none;
    border-bottom:1px solid #ccc;
    background-color: rgba(0, 0, 0, 0);
	outline:none
}
.react-dropdown-tree-select .dropdown .dropdown-content ul{
	margin:0;
	padding:0
}
/*# sourceMappingURL=styles.css.map*/
