.App-header {
  text-align: left;
  position: relative;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:0px;
  background-color: rgb(42, 44, 71);
  --header-height: 70px;
  color: rgb(230, 230, 230);
  position: fixed;
  width:100%;
  z-index: 102;
  box-shadow: 0 2px 3px rgba(0,0,0,0.16);
}

.react-datepicker-popper {
  line-height: normal !important;
}

option {
  background-color: rgb(39, 37, 55) !important;
}

.title {
  line-height: var(--header-height);
  display: flex;
  flex-wrap: wrap;
}

.App-header > .title > .titleDash {
  transform: translate(0, 25%);
  line-height: 35px;
  font-size: min(max(2vw, 1.2em), 2em);
  font-weight: 300;
  margin:0;
  margin-right: 10px;
  margin-left: 40px;
}

.App-header > #date_pickers > div > div > .date_picker {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 20px;
  font-size: max(1vw, 1.2em);
  width: max(8vw, 100px);
  background: transparent;
  border: none;
  border-radius: 2px;
  border-bottom: 2px solid rgb(230, 230, 230);
  color: rgb(230, 230, 230);
}

@media screen and (max-width: 650px) {
  .App-header > .titleDash {
    font-size: 1.5em;
  }
}

.App-header > #date_pickers {
  line-height: var(--header-height);
  margin-right: 50px;
}

#poc_selector {
  line-height: normal;
  width: 200px;
  margin-right: 20px;
  display: inline-block;
  height: 30px;
}

.card {
  vertical-align: top;
  margin:1%;
  display: inline-block;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: rgb(42, 44, 71);
  position: relative;
}

.half {
  vertical-align: top;
  overflow-y: scroll;
  display: inline-block;
  width: 49.8%;
  height: calc(95% - 45px);
  margin-top: 1%;
}
.half:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 900px) {
  .half {
    width: 95%;
    height: calc(600px - 45px);
  }
  .half:first-child {
    height: calc(800px - 45px);
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

body {
  background-color: rgb(28, 33, 59);
}

select {
  background-color:rgba(0,0,0,0);
  color:white;
  width:100px;
  font-size:12px;
  outline: none;
  transition: 0.2s box-shadow;
  margin-left: 10px;
}

.filters > span > input {
  background-color:rgba(0,0,0,0);
  outline: unset;
  color:white !important;
  font-size:12px;
  transition: 0.2s box-shadow;
  margin-left: 10px;
  margin-right: 5px;
}

select:focus {
  box-shadow: 0px 0px 1px 1px rgba(255, 255, 255, 0.5);
}

select:hover {
  box-shadow: 0px 0px 1px 1px rgba(255, 255, 255, 0.5);
}

header > .loading_gif {
  width: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  transform: translate(-50%, - 50%);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.position-relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}

.d-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}
